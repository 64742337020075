import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';

import { NavigationMenu } from '../data/navigation-menu';
import Auth from '../components/Auth';

function Nav() {
  const { logout, currentUser } = useAuth();

  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
    <div className="navbar">
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
       <IconButton onClick={showSidebar} size="medium" sx={{ ml: 2 }}>
         <MenuIcon />
       </IconButton>
       <Typography sx={{ minWidth: 70, display: "flex", alignItems: "center", marginLeft: "8px" }}>
        <img style={{ width: "28px", marginRight: "8px" }} src="../assets/images/app-logo.png" alt="Sensecube" />
        <span className="hidden">Sense<span style={{ fontWeight: "500" }}>cube</span></span>
       </Typography>
     </Box>
     {currentUser && <Auth logout={logout} currentUser={currentUser} />}
    </div>

    <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
       <ul className="nav-menu-items">
         <li className="navbar-toggle">
             <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
              <IconButton onClick={showSidebar} size="medium" sx={{ ml: 2 }}>
                <MenuIcon />
              </IconButton>
              <Typography sx={{ minWidth: 70, display: "flex", alignItems: "center", marginLeft: "12px" }}>
               <img style={{ width: "28px", marginRight: "8px" }} src="../assets/images/app-logo.png" alt="Sensecube" />
               Sense<span style={{ fontWeight: "500" }}>cube</span>
              </Typography>
            </Box>
         </li>
         {NavigationMenu.map((item, index) => {
           return (
             <li key={index} className={item.cName} onClick={showSidebar}>
               <NavLink to={item.path}>
                 {item.icon}
                 <span>{item.title}</span>
               </NavLink>
             </li>
           )
         })}
       </ul>
    </nav>
    <div onClick={showSidebar} className={sidebar ? 'backdrop active' : 'backdrop'} />
    </>
  );
};

export default Nav;
