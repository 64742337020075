import React, { useState, useEffect } from 'react';
import { onSnapshot, collection } from 'firebase/firestore';
import { db } from '../../firebase';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';

function Auth({ logout, currentUser }) {
  const navigate = useNavigate();
  const [notificationsData, setNotificationsData] = useState([]);

  const [authAnchorEl, setAuthAnchorEl] = useState(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const openAuth = Boolean(authAnchorEl);
  const openNotification = Boolean(notificationAnchorEl);

  const handleAuthClick = (event) => {
    setAuthAnchorEl(event.currentTarget);
  };

  const handleAuthClose = () => {
    setAuthAnchorEl(null);
  };

  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  useEffect(() => {

    const unsubscribe = onSnapshot(collection(db, 'notification'), (snapshot) => {
      const items = [];
      snapshot.forEach((doc) => {
        items.push(doc.data());
      });
      setNotificationsData(items);
    });

    return unsubscribe;

  }, []);

  return (
     <>
       <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton onClick={handleNotificationClick} size="medium" sx={{ mr: 2 }}>
          <NotificationsNoneIcon />
        </IconButton>
        <div onClick={handleAuthClick} style={{ marginRight: "16px", cursor: "pointer" }}>
          <IconButton size="small" sx={{ mr: 1 }}>
            <Avatar alt={currentUser.displayName} src={currentUser.photoURL} sx={{ width: 32, height: 32 }} />
          </IconButton>
          <span className="hidden">{currentUser.displayName ? currentUser.displayName: currentUser.email}</span>
        </div>
      </Box>

      <Menu
        anchorEl={notificationAnchorEl}
        open={openNotification}
        onClose={handleNotificationClose}
        onClick={handleNotificationClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            }
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div className="notifications" style={{  width: '100%', maxWidth: 320, maxHeight: 320, overflow: 'auto', overflowX: 'hidden' }}>
          {notificationsData.map((item) => (
            <MenuItem alignItems="flex-start" key={item.id}>
              <ListItemAvatar>
                <Avatar alt={item.title} src="*" />
              </ListItemAvatar>
              <ListItemText
                primary={item.title}
                secondary={
                  <React.Fragment>
                    {item.description}
                  </React.Fragment>
                }
              />
            </MenuItem>
          ))}
        </div>
      </Menu>

      <Menu
        anchorEl={authAnchorEl}
        open={openAuth}
        onClose={handleAuthClose}
        onClick={handleAuthClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          My Account
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={async e => {
            e.preventDefault()
            logout()
            navigate('/login')
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
     </>
  );
};

export default Auth;
