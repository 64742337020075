import React from 'react';
import './App.css';
// import { isMobile } from 'react-device-detect';
// import DetectDevice from './components/DetectDevice';

import { BrowserRouter as Router } from 'react-router-dom';
import AuthContextProvider from './contexts/AuthContext';
import Routes from './Routes';

function App() {
  return (
    <>
      <Router>
        <AuthContextProvider>
          <div className="wrapper">
            <Routes />
          </div>
        </AuthContextProvider>
      </Router>
      {
        // {!isMobile ? (
        //   <Router>
        //     <AuthContextProvider>
        //       <div className="wrapper">
        //         <Routes />
        //       </div>
        //     </AuthContextProvider>
        //   </Router>
        // ) : (
        //   <DetectDevice />
        // )}
      }
    </>
  );
};

export default App;
