import React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';

export const NavigationMenu = [
  {
    title: "Home",
    path: "/",
    icon: <DashboardIcon />,
    cName: "nav-text"
  }
]
