import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, query, limit, orderBy } from 'firebase/firestore';
import { db } from '../../firebase';
import { useParams } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';

import Nav from '../../layouts/Nav';
import Breadcrumbs from '../../components/Breadcrumbs';
import MeterConsumptionChart from '../../components/Charts/MeterConsumptionChart';
import MeterCurrentChart from '../../components/Charts/MeterCurrentChart';
import MeterVoltageChart from '../../components/Charts/MeterVoltageChart';
import Odometer from 'react-odometerjs';
import "odometer/themes/odometer-theme-digital.css";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  borderRadius: "0",
}));

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
    color: "#fff",
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

function MeterView() {
  const { id } = useParams();
  const [meter, setMeter] = useState([]);
  const [meterInfo, setMeterInfo] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const unsubscribe = onSnapshot(collection(db, 'crimes'), (snapshot) => {
      snapshot.forEach((doc) => {
        if (id === doc.id)
        setMeterInfo(doc.data());
      });
    });

    return unsubscribe;

  }, [id]);

  useEffect(() => {

    const snapRef = collection(db, "data_logs/" + id + "/data");
    const q = query(snapRef, orderBy("created_at", "desc"), limit(1));
    const unsubscribe = onSnapshot(q, (snapshot) => {
        const items = [];
        snapshot.forEach((doc) => {
            items.push(doc.data());
        })
        setMeter(items[0]);
        setLoading(false);
    });

    return unsubscribe;

  }, [id]);

  return (
    <>
      <Nav />
      {loading ? (
        <>
          <div className="dataSpinner">
            <div className="spinner">
              <CircularProgress />
              <Typography variant="subtitle1" component="div" gutterBottom>
                Loading...
              </Typography>
            </div>
          </div>
        </>
      ) : (
        <>
          <Breadcrumbs />
          <div className="meterView">
            <div className="gridWrapper">
              <Typography variant="h6" gutterBottom component="div">
                Customer Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <Item className="gridItem">
                    <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "500",fontSize: ".9rem", margin: "0" }}>
                      Name
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {meterInfo.customer_details.name}
                    </Typography>
                  </Item>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <Item className="gridItem">
                    <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "500",fontSize: ".9rem", margin: "0" }}>
                      Address
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {meterInfo.customer_details.address}
                    </Typography>
                  </Item>
                </Grid>

                <Grid item xs={12} sm={3} md={3}>
                  <Item className="gridItem">
                    <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "500",fontSize: ".9rem", margin: "0" }}>
                      USCNO
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {meterInfo.customer_details.uscno}
                    </Typography>
                  </Item>
                </Grid>

                <Grid item xs={12} sm={3} md={3}>
                  <Item className="gridItem">
                    <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "500",fontSize: ".9rem", margin: "0" }}>
                      Area
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {meterInfo.customer_details.area}
                    </Typography>
                  </Item>
                </Grid>

                <Grid item xs={12} sm={3} md={3}>
                  <Item className="gridItem">
                    <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "500",fontSize: ".9rem", margin: "0" }}>
                      CAT
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {meterInfo.customer_details.cat}
                    </Typography>
                  </Item>
                </Grid>

                <Grid item xs={12} sm={3} md={3}>
                  <Item className="gridItem">
                    <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "500",fontSize: ".9rem", margin: "0" }}>
                      SC
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {meterInfo.customer_details.sc}
                    </Typography>
                  </Item>
                </Grid>

                <Grid item xs={12} sm={3} md={3}>
                  <Item className="gridItem">
                    <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "500",fontSize: ".9rem", margin: "0" }}>
                      Phase
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {meterInfo.customer_details.phase}
                    </Typography>
                  </Item>
                </Grid>

                <Grid item xs={12} sm={3} md={3}>
                  <Item className="gridItem">
                    <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "500",fontSize: ".9rem", margin: "0" }}>
                      LOAD
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {meterInfo.customer_details.load}
                    </Typography>
                  </Item>
                </Grid>

                <Grid item xs={12} sm={3} md={3}>
                  <Item className="gridItem">
                    <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "500",fontSize: ".9rem", margin: "0" }}>
                      MF
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {meterInfo.customer_details.mf}
                    </Typography>
                  </Item>
                </Grid>

                <Grid item xs={12} sm={3} md={3}>
                  <Item className="gridItem">
                    <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "500",fontSize: ".9rem", margin: "0" }}>
                      Status
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ textTransform: "capitalize" }}>
                      <span className={meter.status === "active" ? 'text-success' : 'text-error'}>
                       {meter.status}
                      </span>
                    </Typography>
                  </Item>
                </Grid>
              </Grid>
            </div>

            <div className="gridWrapper">
              <Typography variant="h6" gutterBottom component="div">
                Meter Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={4}>
                  <Item className="gridItem" style={{ textAlign: "center", padding: "0", backgroundColor: "transparent" }}>
                     <Stack direction="row" spacing={1} alignItems="center" style={{ justifyContent: "center", marginBottom: "16px" }}>
                      <Typography>OFF</Typography>
                      <FormControlLabel
                        control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                        label=""
                       />
                      <Typography>ON</Typography>
                    </Stack>
                    <div style={{ position: "relative", width: "300px", margin: "auto" }}>
                       <Odometer value={123456} format="d" />
                       <img src="/assets/images/electric-meter.png" width="100%" alt="" />
                    </div>
                  </Item>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                   <Grid container spacing={2}>
                     <Grid item xs={12} sm={6} md={6}>
                       <Item className="gridItem">
                         <Typography variant="body2">
                           Consumption <span style={{ opacity: "0.7" }}>(monthly)</span>
                         </Typography>
                         <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "500", marginBottom: "0.7rem" }}>
                           {meter.consumption}
                         </Typography>
                         <div style={{ height: "200px", position: "relative" }}>
                           <MeterConsumptionChart />
                         </div>
                       </Item>
                     </Grid>

                     <Grid item xs={12} sm={6} md={6}>
                       <Item className="gridItem">
                         <Typography variant="body2">
                           Current
                         </Typography>
                         <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "500", marginBottom: "0.7rem" }}>
                           {meter.current}
                         </Typography>

                         <div style={{ height: "200px", position: "relative" }}>
                           <MeterCurrentChart />
                         </div>
                       </Item>
                     </Grid>

                     <Grid item xs={12} sm={6} md={6}>
                       <Item className="gridItem">
                         <Typography variant="body2">
                           Voltage
                         </Typography>
                         <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "500", marginBottom: "0.7rem" }}>
                           {meter.voltage}
                         </Typography>

                         <div style={{ height: "200px", position: "relative" }}>
                           <MeterVoltageChart />
                         </div>
                       </Item>
                     </Grid>
                   </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MeterView;
