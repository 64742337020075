import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import useMounted from '../../hooks/useMounted';
import { Link } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

function ForgotPassword() {
  const { forgotPassword } = useAuth();
  const mounted = useMounted();

  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);

   const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true)
    forgotPassword(email)
    .then(response => {
      console.log(response);
    })
    .catch(error => {
      switch(error.code) {
        case 'auth/invalid-email':
              setError("Invalid email.")
              break;
        case 'auth/missing-android-pkg-name':
              setError("Missing android pkg name.")
              break;
        case 'auth/missing-continue-uri':
              setError("Missing continue uri.")
              break;
        case 'auth/missing-ios-bundle-id':
              setError("Missing ios bundle id.")
              break;
        case 'auth/invalid-continue-uri':
              setError("Invalid continue uri.")
              break;
        case 'auth/unauthorized-continue-uri':
              setError("Unauthorized continue uri.")
              break;
        case 'auth/user-not-found':
              setError("User not found.")
              break;
        default:
              setError(error.message)
      }
      setOpen(true);
    })
    .finally(() => {
      if(mounted.current) {
        setIsSubmitting(false)
      }
    })
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <div className="forgotPassword">
        {error && (
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
           <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
             {error}
           </Alert>
         </Snackbar>
        )}
        <Card className="forgotPasswordBox">
          <form onSubmit={handleSubmit}>
            <CardContent>
              <div style={{ textAlign: "center" }}>
                <img src="assets/images/logo.png" alt="Sensecube" style={{ maxWidth: "170px" }} />
              </div>

              <Typography gutterBottom variant="h5" component="div" sx={{ mb: 3, mt: 3 }}>
                Forgot Password
              </Typography>

              <FormControl fullWidth>
                <TextField
                  required
                  label="Email"
                  variant="outlined"
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                 />
               </FormControl>
            </CardContent>
            <CardActions sx={{ padding: "0 16px", mb: 2 }}>
              <Button className="btn-theme" sx={{ minWidth: '100%' }} type="submit" disabled={isSubmitting} variant="contained" size="large">Forgot Password</Button>
            </CardActions>
          </form>
          <Typography gutterBottom variant="p" component="div" style={{ margin: "16px 0", textAlign: "center" }}>
            Need an account? <Link to="/signup">Sign Up</Link>
          </Typography>
       </Card>
     </div>
    </>
  );
};

export default ForgotPassword;
