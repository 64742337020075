import React from 'react';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import VoltageChart from '../../../components/Charts/VoltageChart';
import CurrentChart from '../../../components/Charts/CurrentChart';
import FrequencyChart from '../../../components/Charts/FrequencyChart';
// import TemperatureChart from '../../../components/Charts/TemperatureChart';
// import HumidityChart from '../../../components/Charts/HumidityChart';
// import EarthingChart from '../../../components/Charts/EarthingChart';
import ActivePowerChart from '../../../components/Charts/ActivePowerChart';
import ReactivePowerChart from '../../../components/Charts/ReactivePowerChart';
import ApparentPowerChart from '../../../components/Charts/ApparentPowerChart';
// import HarmonicChart from '../../../components/Charts/HarmonicChart';

import InvertColorsIcon from '@mui/icons-material/InvertColors';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  borderRadius: "0",
}));

function Dashboard({ transformer, id }) {

  return (
    <>
     <div className="dashboardData">
       <div className="gridWrapper">
         <Typography variant="h6" gutterBottom component="div">
           Sensors
         </Typography>
         <Grid container spacing={2}>
           <Grid item xs={12} sm={6} md={3}>
             <Item className="gridItem">
               <div style={{ display: "flex" }}>
                 <div>
                   <InvertColorsIcon style={{ fill: "#ddd", fontSize: "28px" }} />
                 </div>
                 <div style={{ marginLeft: "16px" }}>
                   <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "500",fontSize: ".9rem", margin: "0" }}>
                     Oil Level
                   </Typography>
                   <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: ".9rem", margin: "0", color: "rgb(26 32 39 / 78%)" }}>
                     Centimeter
                   </Typography>
                   <Typography variant="h4" gutterBottom component="div" style={{ margin: "24px 0px 0px 0px", fontWeight: "400", textAlign: "left", fontSize: "2.425rem" }}>
                     -
                   </Typography>
                 </div>
               </div>
             </Item>
           </Grid>

           <Grid item xs={12} sm={6} md={3}>
             <Item className="gridItem">
               <div style={{ display: "flex" }}>
                 <div>
                   <DeviceThermostatIcon style={{ fill: "#ddd", fontSize: "28px" }} />
                 </div>
                 <div style={{ marginLeft: "16px" }}>
                   <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "500",fontSize: ".9rem", margin: "0" }}>
                     Temperature
                   </Typography>
                   <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: ".9rem", margin: "0", color: "rgb(26 32 39 / 78%)" }}>
                     Celsius
                   </Typography>
                   <Typography variant="h4" gutterBottom component="div" style={{ margin: "24px 0px 0px 0px", fontWeight: "400", textAlign: "left", fontSize: "2.425rem" }}>
                     -
                   </Typography>
                 </div>
               </div>
             </Item>
           </Grid>

           <Grid item xs={12} sm={6} md={3}>
             <Item className="gridItem">
               <div style={{ display: "flex" }}>
                 <div>
                   <InvertColorsIcon style={{ fill: "#ddd", fontSize: "28px" }} />
                 </div>
                 <div style={{ marginLeft: "16px" }}>
                   <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "500",fontSize: ".9rem", margin: "0" }}>
                     Humidity
                   </Typography>
                   <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: ".9rem", margin: "0", color: "rgb(26 32 39 / 78%)" }}>
                     %rh
                   </Typography>
                   <Typography variant="h4" gutterBottom component="div" style={{ margin: "24px 0px 0px 0px", fontWeight: "400", textAlign: "left", fontSize: "2.425rem" }}>
                     -
                   </Typography>
                 </div>
               </div>
             </Item>
           </Grid>

           <Grid item xs={12} sm={6} md={3}>
             <Item className="gridItem">
               <div style={{ display: "flex" }}>
                 <div>
                   <AlignHorizontalCenterIcon style={{ fill: "#ddd", fontSize: "28px" }} />
                 </div>
                 <div style={{ marginLeft: "16px" }}>
                   <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "500",fontSize: ".9rem", margin: "0" }}>
                     Earthing
                   </Typography>
                   <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: ".9rem", margin: "0", color: "rgb(26 32 39 / 78%)" }}>
                     Ohm
                   </Typography>
                   <Typography variant="h4" gutterBottom component="div" style={{ margin: "24px 0px 0px 0px", fontWeight: "400", textAlign: "left", fontSize: "2.425rem" }}>
                     -
                   </Typography>
                 </div>
               </div>
             </Item>
           </Grid>
         </Grid>
       </div>

       {
        //  <div className="gridWrapper">
        //   <Grid container spacing={2}>
        //      <Grid item xs={12} sm={12} md={12}>
        //        <Item style={{ height: "450px", position: "relative" }}>
        //          <TemperatureChart id={id} />
        //        </Item>
        //      </Grid>
        //
        //      <Grid item xs={12} sm={6} md={6}>
        //        <Item style={{ height: "350px", position: "relative" }}>
        //          <HumidityChart id={id} />
        //        </Item>
        //      </Grid>
        //
        //      <Grid item xs={12} sm={6} md={6}>
        //        <Item style={{ height: "350px", position: "relative" }}>
        //          <EarthingChart id={id} />
        //        </Item>
        //      </Grid>
        //   </Grid>
        // </div>
       }

       <div className="phaseWrapper">
         <Typography variant="h6" gutterBottom component="div">
           Voltage
         </Typography>
         <Grid container spacing={2}>
           <Grid item xs={12} sm={6} md={3}>
             <Item className="red">
               <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                 phase A
               </Typography>
               <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                 {transformer.voltage1} <span style={{ fontSize: "18px" }}>V</span>
               </Typography>
             </Item>
           </Grid>
           <Grid item xs={12} sm={6} md={3}>
             <Item className="yellow">
               <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                 phase B
               </Typography>
               <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                 {transformer.voltage2} <span style={{ fontSize: "18px" }}>V</span>
               </Typography>
             </Item>
           </Grid>
           <Grid item xs={12} sm={6} md={3}>
             <Item className="blue">
               <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                 phase C
               </Typography>
               <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                 {transformer.voltage3} <span style={{ fontSize: "18px" }}>V</span>
               </Typography>
             </Item>
           </Grid>
           <Grid item xs={12} sm={6} md={3}>
             <Item>
               <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                 Average
               </Typography>
               <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                 {transformer.voltageavg} <span style={{ fontSize: "18px" }}>V</span>
               </Typography>
             </Item>
           </Grid>
           <Grid item xs={12} sm={12} md={12}>
             <Item className="chart-height" style={{ height: "450px", position: "relative" }}>
               <VoltageChart id={id} />
             </Item>
           </Grid>
         </Grid>
       </div>

        <div className="phaseWrapper">
          <Typography variant="h6" gutterBottom component="div">
            Current
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Item className="red">
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                  phase A
                </Typography>
                <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                  {transformer.current1} <span style={{ fontSize: "18px" }}>A</span>
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Item className="yellow">
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                  phase B
                </Typography>
                <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                  {transformer.current2} <span style={{ fontSize: "18px" }}>A</span>
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Item className="blue">
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                  phase C
                </Typography>
                <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                  {transformer.current3} <span style={{ fontSize: "18px" }}>A</span>
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Item>
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                  Average
                </Typography>
                <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                  {transformer.currentavg} <span style={{ fontSize: "18px" }}>A</span>
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Item className="chart-height" style={{ height: "450px", position: "relative" }}>
                <CurrentChart id={id} />
              </Item>
            </Grid>
          </Grid>
        </div>

        <div className="phaseWrapper">
          <Typography variant="h6" gutterBottom component="div">
            Active Power
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={2}>
              <Item className="red">
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                  phase A
                </Typography>
                <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                  {parseFloat(transformer.kw1).toFixed(3)} <span style={{ fontSize: "18px" }}>W</span>
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Item className="yellow">
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                  phase B
                </Typography>
                <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                  {parseFloat(transformer.kw2).toFixed(3)} <span style={{ fontSize: "18px" }}>W</span>
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Item className="blue">
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                  phase C
                </Typography>
                <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                  {parseFloat(transformer.kw3).toFixed(3)} <span style={{ fontSize: "18px" }}>W</span>
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Item>
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                  Max Power
                </Typography>
                <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                  {transformer.maxkw} <span style={{ fontSize: "18px" }}>W</span>
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Item>
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                  Min Power
                </Typography>
                <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                  {parseFloat(transformer.minkw).toFixed(3)} <span style={{ fontSize: "18px" }}>W</span>
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Item>
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                  Total Power
                </Typography>
                <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                  {parseFloat(transformer.totalkw).toFixed(3)} <span style={{ fontSize: "18px" }}>W</span>
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Item className="chart-height" style={{ height: "450px", position: "relative" }}>
                <ActivePowerChart id={id} />
              </Item>
            </Grid>
          </Grid>
        </div>

        <div className="phaseWrapper">
          <Typography variant="h6" gutterBottom component="div">
            Reactive Power
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={2}>
              <Item className="red">
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                  phase A
                </Typography>
                <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                  {parseFloat(transformer.kvar1).toFixed(3)} <span style={{ fontSize: "18px" }}>var</span>
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Item className="yellow">
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                  phase B
                </Typography>
                <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                  {parseFloat(transformer.kvar2).toFixed(3)} <span style={{ fontSize: "18px" }}>var</span>
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Item className="blue">
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                  phase C
                </Typography>
                <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                  {parseFloat(transformer.kvar3).toFixed(3)} <span style={{ fontSize: "18px" }}>var</span>
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Item>
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                  Max Power
                </Typography>
                <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                  {transformer.maxkvar} <span style={{ fontSize: "18px" }}>var</span>
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Item>
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                  Min Power
                </Typography>
                <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                  {parseFloat(transformer.minkvar).toFixed(3)} <span style={{ fontSize: "18px" }}>var</span>
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Item>
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                  Total Power
                </Typography>
                <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                  {parseFloat(transformer.totalkvar).toFixed(3)} <span style={{ fontSize: "18px" }}>var</span>
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Item className="chart-height" style={{ height: "450px", position: "relative" }}>
                <ReactivePowerChart id={id} />
              </Item>
            </Grid>
          </Grid>
        </div>

        <div className="phaseWrapper">
          <Typography variant="h6" gutterBottom component="div">
            Apparent Power
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={2}>
              <Item className="red">
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                  phase A
                </Typography>
                <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                  {parseFloat(transformer.kva1).toFixed(3)} <span style={{ fontSize: "18px" }}>VA</span>
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Item className="yellow">
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                  phase B
                </Typography>
                <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                  {parseFloat(transformer.kva2).toFixed(3)} <span style={{ fontSize: "18px" }}>VA</span>
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Item className="blue">
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                  phase C
                </Typography>
                <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                  {parseFloat(transformer.kva3).toFixed(3)} <span style={{ fontSize: "18px" }}>VA</span>
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Item>
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                  Max Power
                </Typography>
                <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                  {transformer.maxkva} <span style={{ fontSize: "18px" }}>VA</span>
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Item>
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                  Import Power
                </Typography>
                <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                  {transformer.importkvah} <span style={{ fontSize: "18px" }}>VA</span>
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Item>
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                  Total Power
                </Typography>
                <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                  {parseFloat(transformer.totalkva).toFixed(3)} <span style={{ fontSize: "18px" }}>VA</span>
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Item className="chart-height" style={{ height: "450px", position: "relative" }}>
                <ApparentPowerChart id={id} />
              </Item>
            </Grid>
          </Grid>
        </div>

        <div className="phaseWrapper">
          <Typography variant="h6" gutterBottom component="div">
            Frequency
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Item>
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                  Frequency
                </Typography>
                <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                  {transformer.frequency} <span style={{ fontSize: "18px" }}>Hz</span>
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Item className="chart-height" style={{ height: "450px", position: "relative" }}>
                <FrequencyChart id={id} />
              </Item>
            </Grid>
          </Grid>
        </div>

        <div className="phaseWrapper">
          <Typography variant="h6" gutterBottom component="div">
            Power Factor
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Item className="red">
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                  phase A
                </Typography>
                <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                  {parseFloat(transformer.pf1).toFixed(3)}
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Item className="yellow">
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                  phase B
                </Typography>
                <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                  {parseFloat(transformer.pf2).toFixed(3)}
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Item className="blue">
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                  phase C
                </Typography>
                <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                  {parseFloat(transformer.pf3).toFixed(3)}
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Item>
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: "400",fontSize: "0.9rem", margin: "0" }}>
                  Average
                </Typography>
                <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, fontWeight: "300" }}>
                  {parseFloat(transformer.pfavg).toFixed(3)}
                </Typography>
              </Item>
            </Grid>
          </Grid>
        </div>

       {
         // <div className="chartWrapper">
         //  <Grid container spacing={2}>
         //     <Grid item xs={12} sm={12} md={12}>
         //       <Item style={{ height: "450px", position: "relative" }}>
         //         <HarmonicChart id={id} />
         //       </Item>
         //     </Grid>
         //   </Grid>
         // </div>
       }
     </div>
    </>
  );
};

export default Dashboard;
