import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import useMounted from '../../hooks/useMounted';
import { Link, useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

function Login() {
  const { login, signInWithGoogle } = useAuth();
  const mounted = useMounted();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true)
    login(email, password)
    .then((response) => {
      navigate('/');
    })
    .catch((error) => {
      switch(error.code) {
        case 'auth/invalid-email':
              setError("Invalid email.")
              break;
        case 'auth/user-disabled':
              setError("User disabled.")
              break;
        case 'auth/user-not-found':
              setError("User not found.")
              break;
        case 'auth/wrong-password':
              setError("Wrong password.")
              break;
        default:
              setError(error.message)
      }
      setOpen(true);
    })
    .finally(() => {
      if(mounted.current) {
        setIsSubmitting(false)
      }
    })
  };

  const signInGoogle = () => {
    signInWithGoogle()
    .then(user => {
      navigate('/');
    })
    .catch((error) => {
      switch(error.code) {
        case 'auth/account-exists-with-different-credential':
              setError("Account exists with different credential.")
              break;
        case 'auth/auth-domain-config-required':
              setError("Auth domain config required.")
              break;
        case 'auth/cancelled-popup-request':
              setError("Cancelled popup request.")
              break;
        case 'auth/operation-not-allowed':
              setError("Operation not allowed.")
              break;
        case 'auth/operation-not-supported-in-this-environment':
              setError("Operation not supported in this environment.")
              break;
        case 'auth/popup-blocked':
              setError("Popup blocked.")
              break;
        case 'auth/popup-closed-by-user':
              setError("Popup closed by user.")
              break;
        case 'auth/unauthorized-domain':
              setError("Unauthorized domain.")
              break;
        default:
              setError(error.message)
      }
      setOpen(true);
    })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <div className="login">
        {error && (
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
           <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
             {error}
           </Alert>
         </Snackbar>
        )}
        <Card className="loginBox">
          <form onSubmit={handleSubmit}>
            <CardContent>
              <div style={{ textAlign: "center" }}>
                <img src="assets/images/logo.png" alt="Sensecube" style={{ maxWidth: "170px" }} />
              </div>

              <Typography gutterBottom variant="h5" component="div" sx={{ mb: 3, mt: 3 }}>
                Sign In
              </Typography>

              <FormControl fullWidth sx={{ mb: 3 }}>
                <TextField
                  required
                  label="Email"
                  variant="outlined"
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                 />
               </FormControl>

               <FormControl fullWidth>
                 <TextField
                   required
                   label="Password"
                   variant="outlined"
                   type="password"
                   value={password}
                   onChange={e => setPassword(e.target.value)}
                  />
                </FormControl>
            </CardContent>
            <CardActions sx={{ padding: "0 16px", mb: 2 }}>
              <Button className="btn-theme" sx={{ minWidth: '100%' }} type="submit" disabled={isSubmitting} variant="contained" size="large">Sign In</Button>
            </CardActions>

            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0 16px" }}>
              <div>
                <Link to="/signup" style={{ marginBottom: "16px", display: "block", textAlign: "right", color: "inherit" }}>Don't have an account?</Link>
              </div>

              <div>
                <Link to="/forgot-password" style={{ marginBottom: "16px", display: "block", textAlign: "left", color: "inherit" }}>Forgot Password?</Link>
              </div>
            </div>

            <div style={{ padding: "0 16px", textAlign: "center" }}>
             <Divider>OR</Divider>
             <Button
               variant="contained"
               onClick={signInGoogle}
               style={{ margin: "16px 0", padding: "8px 16px", backgroundColor: "#fff" }}
             >
               <div style={{ fontSize: "inherit", fontWeight: "inherit", display: "flex", alignItems: "center" }}>
                 <img src="assets/images/socials/google.svg" alt="Google" style={{ width: "24px", marginRight: "16px" }} /> Sign in with Google
               </div>
             </Button>
            </div>
          </form>
       </Card>
     </div>
    </>
  );
};

export default Login;
