import React, { useState, useMemo, useEffect } from 'react';
import axios from 'axios';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';

import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';

import CircularProgress from '@mui/material/CircularProgress';

function HistoricalData({ id }) {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState();
  const [dateTime, setDateTime] = useState('');
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');

  // const dateValue: Date = new Date();
  const maxDate: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

  const onChange = (args: ChangedEventArgs): void => {
    const date = args.value;
    if(date) {
      const str =
          date.getFullYear() + "-" +
          ("0" + (date.getMonth()+1)).slice(-2) + "-" +
          ("0" + date.getDate()).slice(-2) + " " +
          ("0" + date.getHours()).slice(-2) + ":" +
          ("0" + date.getMinutes()).slice(-2) + ":" +
          ("0" + date.getSeconds()).slice(-2);

      setDateTime(str);
    }
  }

  const applyFilter = () => {
    // console.log(dateTime,"dateTime");
  }

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onBtnExport = () => {
    gridApi.exportDataAsCsv();
  };

  const onFilterTextChange = (e) => {
    gridApi.setQuickFilter(e.target.value);
  };

  const colDefs = useMemo(() => [{
      headerName: 'Date / Time',
      field: 'created_at',
      width: 170,
      filter: 'agTextColumnFilter'
    },{
      headerName: 'Voltage',
      children: [{
          headerName: 'Phase A',
          field: 'phase_a_voltage',
          width: 120,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Phase B',
          field: 'phase_b_voltage',
          width: 120,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Phase C',
          field: 'phase_c_voltage',
          width: 120,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Average',
          field: 'avgvoltage',
          width: 120,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Voltage (AB)',
          field: 'abvoltage',
          width: 140,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Voltage (BC)',
          field: 'bcvoltage',
          width: 140,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Voltage (AC)',
          field: 'acvoltage',
          width: 140,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Average (ABC)',
          field: 'avgabcvoltage',
          width: 150,
          filter: 'agTextColumnFilter'
        }]
    },{
      headerName: 'Current',
      children: [{
          headerName: 'Phase A',
          field: 'current1',
          width: 120,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Phase B',
          field: 'current2',
          width: 120,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Phase C',
          field: 'current3',
          width: 120,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Average',
          field: 'avgcurrent',
          width: 120,
          filter: 'agTextColumnFilter'
        }]
    },{
      headerName: 'Active Power',
      children: [{
          headerName: 'Phase A',
          field: 'kw1',
          width: 120,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Phase B',
          field: 'kw2',
          width: 120,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Phase C',
          field: 'kw3',
          width: 120,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Total Power',
          field: 'totalkw',
          width: 150,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Import Power',
          field: 'importkwh',
          width: 150,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Min Power',
          field: 'minkw',
          width: 150,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Max Power',
          field: 'maxkw',
          width: 150,
          filter: 'agTextColumnFilter'
        }]
    },{
      headerName: 'Reactive Power',
      children: [{
          headerName: 'Phase A',
          field: 'kvar1',
          width: 120,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Phase B',
          field: 'kvar2',
          width: 120,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Phase C',
          field: 'kvar3',
          width: 120,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Total Power',
          field: 'totalkvar',
          width: 150,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Import Power',
          field: 'importkvarh',
          width: 150,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Min Power',
          field: 'minkvar',
          width: 150,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Max Power',
          field: 'maxkvar',
          width: 150,
          filter: 'agTextColumnFilter'
        }]
    },{
      headerName: 'Apparent Power',
      children: [{
          headerName: 'Phase A',
          field: 'kva1',
          width: 120,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Phase B',
          field: 'kva2',
          width: 120,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Phase C',
          field: 'kva3',
          width: 120,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Total Power',
          field: 'totalkva',
          width: 150,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Import Power',
          field: 'importkvah',
          width: 150,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Max Power',
          field: 'maxkva',
          width: 150,
          filter: 'agTextColumnFilter'
        }]
    },{
      headerName: 'Power Factor',
      children: [{
          headerName: 'Phase A',
          field: 'pf1',
          width: 120,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Phase B',
          field: 'pf2',
          width: 120,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Phase C',
          field: 'pf3',
          width: 120,
          filter: 'agTextColumnFilter'
        },{
          headerName: 'Average',
          field: 'avgpf',
          width: 120,
          filter: 'agTextColumnFilter'
        }]
    },{
        headerName: 'Temperature',
        field: '',
        width: 140,
        filter: 'agTextColumnFilter'
      },{
        headerName: 'Frequency',
        field: 'frequency',
        width: 130,
        filter: 'agTextColumnFilter'
      },{
        headerName: 'Humidity',
        field: '',
        width: 120,
        filter: 'agTextColumnFilter'
      },{
        headerName: 'Neutral Current',
        field: '',
        width: 160,
        filter: 'agTextColumnFilter'
      }
  ], []);

  useEffect(() => {

    const unsubscribe = axios.get('https://0q3vj1f11e.execute-api.ap-south-1.amazonaws.com/prod/gettranscodata?deviceId='+id+'&limit=100&offset=0').then((response) => {
        if (response) {
          setRowData(response.data.data1);
          setLoading(false);
        } else {
          setMessage('No data found')
        }

    });

    return unsubscribe;

  }, [id])

  return (
    <>
      <div className="historicalData">
        {loading ? (
          <div style={{ position: "relative", height: "calc(100vh - 200px)" }}>
            <div className="tabDataSpinner">
              <CircularProgress />
            </div>
          </div>
        ) : (
          <>
            {!message ? (
              <>
                <div className="filter" style={{ display: "flex", alignItems: "center", marginBottom: "24px" }}>
                  <div style={{ flex: "auto" }}>
                    <div className="searchInput">
                      <SearchIcon />
                      <input type="search" placeholder="Search…" onChange={onFilterTextChange} />
                    </div>
                  </div>
                  <div>
                    <div className="filter-meta" style={{ display: "flex", alignItems: "center" }}>
                      <div className="dateTimeFilter">
                        <DateTimePickerComponent
                         placeholder="Choose a Date and Time"
                         // value={dateValue}
                         max={maxDate}
                         format='yyyy-MM-dd HH:mm:ss'
                         change={onChange}
                         />
                         <Button className="btn-outline" variant="outlined" color="primary" onClick={applyFilter}>
                           Apply
                         </Button>
                       </div>
                       <div className="hidden">
                        <Button className="btn-theme" variant="contained" color="primary" startIcon={<DownloadIcon style={{ fill: "#fff" }} />} onClick={() => onBtnExport()}>
                          Export to CSV
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ag-theme-alpine" style={{ width: '100%', height: 'calc(100% - 60px)' }}>
                  <div style={{ height: "585px" }}>
                    <AgGridReact
                      defaultColDef={{
                        sortable: true,
                        resizable: true,
                        filter: true,
                      }}
                      debug={false}
                      rowData={rowData}
                      columnDefs={colDefs}
                      pagination={true}
                      suppressExcelExport={true}
                      paginationAutoPageSize={true}
                      onGridReady={onGridReady}
                    >
                    </AgGridReact>
                  </div>
                </div>
              </>
            ) : (
              <div className="noDataMessage">{message}</div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default HistoricalData;
