import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';

function NotFound() {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate('/');
  }

  return (
    <>
      <div className="notFound">
          <div className="middle">
             <div className="inner-middle">
                <h1>404</h1>
                <h6>Ooops!!</h6>
                <p>That page doesn't exist or is unavailable.</p>
                <Button className="btn-theme" onClick={goToHome} variant="contained" size="large">Go Back to Home</Button>
            </div>
          </div>
      </div>
    </>
  )
};

export default NotFound;
