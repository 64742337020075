import React from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

export default function NextBreadcrumbs() {
  const location = useLocation();

  function generateBreadcrumbs() {
    const pathname = location.pathname.split("?")[0];

	  const pathnames = pathname.split("/").filter(v => v.length > 0);

  	const crumblist = pathnames.map((subpath, idx) => {
  	    const href = "/" + pathnames.slice(0, idx + 1).join("/");

    	  return { href, text: subpath };
      })

  	  return [{ href: "/", text: "Home" }, ...crumblist];
  }

  const breadcrumbs = generateBreadcrumbs();

  const last = breadcrumbs.length - 1;

  return (
    <Breadcrumbs maxItems={4} aria-label="breadcrumb" className="breadcrumbs">
      {breadcrumbs.map((item, idx) => (
        <div key={item}>
          {last === idx ? (
            <Typography color="text.primary" sx={{ textTransform: "capitalize" }}>{item.text}</Typography>
          ) : (
            <Link href={item.href} underline="hover" sx={{ textTransform: "capitalize", color: "rgba(0, 0, 0, 0.6)" }}>
              {item.text}
            </Link>
          )}
        </div>
      ))}
    </Breadcrumbs>
  );
}
