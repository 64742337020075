import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCAra0jvgmp94HfFzMljkW67dUZuK2kXVE",
  authDomain: "spotsense-94f72.firebaseapp.com",
  databaseURL: "https://spotsense-94f72-default-rtdb.firebaseio.com",
  projectId: "spotsense-94f72",
  storageBucket: "spotsense-94f72.appspot.com",
  messagingSenderId: "959186872886",
  appId: "1:959186872886:web:9fd73286a025d181e30697"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);

export default app;
