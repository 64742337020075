import React from 'react';
import Nav from '../../layouts/Nav';
import Map from './Map';

function Home() {
  return (
    <div className="home">
      <Nav />
      <Map />
    </div>
  );
};

export default Home;
