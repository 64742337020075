import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

function PrivateRoute() {
  const session = localStorage.getItem("loginUser");

  if(session !== "null") {
    return <Outlet />
  } else {
    return <Navigate to="/login" />
  }

}

export default PrivateRoute
