import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onSnapshot, collection } from 'firebase/firestore';
import { db } from '../../firebase';

import Fab from '@mui/material/Fab';
import MapIcon from '@mui/icons-material/Map';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';

import Nav from '../../layouts/Nav';
import Breadcrumbs from '../../components/Breadcrumbs';


function Transformers() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [transformers, setTransformers] = useState([]);

  const [filter, setFliter] = useState(false);
  const [status, setStatus] = useState('');
  const [phase, setPhase] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {

    const unsubscribe = onSnapshot(collection(db, 'crimes'), (snapshot) => {
      const items = [];
      snapshot.forEach((doc) => {
        const transformer = doc.data();
        transformer.id = doc.id;
        items.push(transformer);
      });
      setTransformers(items);
      if(items.length > 0) {
        setLoading(false);
      } else {
        setMessage('No data found');
      }
    });

    return unsubscribe;

  }, []);

  const openFilter = () => {
    setFliter(true);
  }

  const closeFilter = () => {
    setFliter(false);
    setStatus('');
    setPhase('');
  }

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handlePhaseChange = (event) => {
    setPhase(event.target.value);
  };

  const transformerView = (id) => {
    navigate(`/transformers/${id}`)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Nav />
      <Breadcrumbs />
      <div className="transformers">
        {loading ? (
          <div className="innerTransformers">
            <div className="tableSpinner">
              <CircularProgress />
            </div>
          </div>
        ) : (
          <>
            {!message ? (
              <>
              <Paper sx={{ width: '100%' }}>
                <Toolbar className="toolbar" sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
                  {filter ? (
                    <>
                      <div style={{ flex: '1 1 100%' }}>
                        <FormControl sx={{ minWidth: 140, mr: 2 }}>
                          <Select
                            value={status}
                            displayEmpty
                            onChange={handleStatusChange}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return <em>Status</em>;
                              }

                              return selected;
                            }}
                          >
                            <MenuItem disabled value="">
                              <em>Status</em>
                            </MenuItem>
                            <MenuItem value="Active">Active</MenuItem>
                            <MenuItem value="Deactive">Deactive</MenuItem>
                          </Select>
                        </FormControl>

                        <FormControl sx={{ minWidth: 140}}>
                          <Select
                            value={phase}
                            displayEmpty
                            onChange={handlePhaseChange}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return <em>Phase</em>;
                              }

                              return selected;
                            }}
                          >
                            <MenuItem disabled value="">
                              <em>Phase</em>
                            </MenuItem>
                            <MenuItem value="2 phase">2 phase</MenuItem>
                            <MenuItem value="3 phase">3 phase</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <IconButton onClick={closeFilter}>
                       <CloseIcon />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <Typography
                         sx={{ flex: '1 1 100%' }}
                         variant="h6"
                         id="tableTitle"
                         component="div"
                       >
                         Filter
                       </Typography>

                       <IconButton onClick={openFilter}>
                        <FilterListIcon />
                       </IconButton>
                     </>
                  )}
                </Toolbar>
                <TableContainer>
                  <Table aria-label="data table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Sr. No</TableCell>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="left">Address</TableCell>
                        <TableCell align="left">Model Number</TableCell>
                        <TableCell align="left">Phase</TableCell>
                        <TableCell align="left">KVA</TableCell>
                        <TableCell align="left">Status</TableCell>
                        <TableCell align="left">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {transformers
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          return (
                            <TableRow
                              key={row.id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                {row.srNo}
                              </TableCell>
                              <TableCell align="left">{row.title}</TableCell>
                              <TableCell align="left">{row.address}</TableCell>
                              <TableCell align="left">{row.modelNo}</TableCell>
                              <TableCell align="left">{row.phase}</TableCell>
                              <TableCell align="left">{row.kva}</TableCell>
                              <TableCell align="left">
                                <span className={row.status === "active" ? 'text-success' : 'text-error'} style={{ textTransform: "capitalize" }}>
                                 {row.status}
                                </span>
                              </TableCell>
                              <TableCell align="left">
                                <Button onClick={() => transformerView(row.id)} size="small" variant="outlined" className="info-btn">View Info</Button>
                              </TableCell>
                            </TableRow>
                         );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50]}
                  component="div"
                  count={transformers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  className="tablePagination"
                />
              </Paper>
              </>
            ) : (
              <div className="innerTransformers">
               <div className="noDataMessage">{message}</div>
              </div>
            )}
          </>
        )}

        <Fab
        size="large"
        color="inherit"
        aria-label="List View"
        style={{
          background: "#ef5c29",
          position: "fixed",
          right: "1.5rem",
          bottom:" 1.5rem"
         }}
         onClick={() => {
           navigate('/');
         }}
         >
          <MapIcon style={{ fill: "#fff" }} />
        </Fab>
      </div>
    </>
  );
};

export default Transformers;
