import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import useMounted from '../../hooks/useMounted';
import { Link, useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

function SignUp() {
  const { register } = useAuth();
  const mounted = useMounted();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true)
    if(password === confirmPassword) {
      register(email, password)
      .then((response) => {
        navigate('/');
      })
      .catch((error) => {
        switch(error.code) {
          case 'auth/email-already-in-use':
                setError("E-mail already in use.")
                break;
          case 'auth/invalid-email':
                setError("Invalid email")
                break;
          case 'auth/operation-not-allowed':
                setError("Operation not allowed")
                break;
          case 'auth/weak-password':
                setError("The password is too weak.")
                break;
          default:
                setError(error.message)
        }
        setOpen(true);
      })
      .finally(() => {
        if(mounted.current) {
          setIsSubmitting(false)
        }
      })
    } else {
      setIsSubmitting(false);
      setError("Password do not match!");
      setOpen(true);
    }

  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <div className="register">
        {error && (
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
           <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
             {error}
           </Alert>
         </Snackbar>
        )}
        <Card className="registerBox">
          <form onSubmit={handleSubmit}>
            <CardContent>
              <div style={{ textAlign: "center" }}>
                <img src="assets/images/logo.png" alt="Sensecube" style={{ maxWidth: "170px" }} />
              </div>

              <Typography gutterBottom variant="h5" component="div" sx={{ mb: 3, mt: 3 }}>
                Sign Up
              </Typography>

                <FormControl fullWidth sx={{ mb: 3 }}>
                  <TextField
                    required
                    label="Email"
                    variant="outlined"
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                   />
                 </FormControl>

               <FormControl fullWidth sx={{ mb: 3 }}>
                 <TextField
                   required
                   label="Password"
                   variant="outlined"
                   type="password"
                   value={password}
                   onChange={e => setPassword(e.target.value)}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    required
                    label="Confirm Password"
                    variant="outlined"
                    type="password"
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                   />
                 </FormControl>
            </CardContent>
            <CardActions sx={{ padding: "0 16px", mb: 2 }}>
              <Button className="btn-theme" sx={{ minWidth: '100%' }} type="submit" disabled={isSubmitting} variant="contained" size="large">Sign Up</Button>
            </CardActions>
          </form>
          <Typography gutterBottom variant="p" component="div" style={{ margin: "16px 0", textAlign: "center" }}>
            Already have an account? <Link to="/login">Sign In</Link>
          </Typography>
       </Card>
     </div>
    </>
  );
};

export default SignUp;
