import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { onSnapshot, collection } from 'firebase/firestore';
import { db } from '../../../firebase';

import ReactMapGL, { Marker, FlyToInterpolator, Popup } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import useSupercluster from 'use-supercluster';

import Layers from '../Layers';
import Sidebar from '../Sidebar';

import Fab from '@mui/material/Fab';
import MenuIcon from '@mui/icons-material/Menu';

function Map(props) {
  const navigate = useNavigate();

  // setup map
  const [viewport, setViewport] = useState({
    latitude: 17.3850,
    longitude: 78.4867,
    width: "100%",
    height: "100%",
    zoom: 9
  });

  const mapRef = useRef();
  const [transformers, setTransformers] = useState([]);

  useEffect(() => {

    const unsubscribe = onSnapshot(collection(db, 'crimes'), (snapshot) => {
      const items = [];
      snapshot.forEach((doc) => {
        const transformer = doc.data();
        transformer.id = doc.id;
        items.push(transformer);
      });
      setTransformers(items);
    });

    return unsubscribe;

  }, []);

  const points = transformers.map(item => ({
    type: "Feature",
    properties: { cluster: false, id: item.id, title: item.title, status: item.status, address: item.address, kva: item.kva, modelNo: item.modelNo, phase: item.phase, srNo: item.srNo, type: item.type, service_number: item.service_number, customer_details: item.customer_details },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(item.location.longitude),
        parseFloat(item.location.latitude)
      ]
    }
  }));

  // get map bounds
  const bounds = mapRef.current
    ? mapRef.current
    .getMap()
    .getBounds()
    .toArray()
    .flat()
    : null;

  // get clusters
  const { clusters, supercluster } = useSupercluster({
    points,
    zoom: viewport.zoom,
    bounds,
    options: { radius: 75, maxZoom: 20 }
  });

  const [sidebar, setSidebar] = useState(false);
  const [info, setInfo] = useState(null);

  // const [showPopup, setShowPopup] = useState(false);
  // const [popupInfo, setPopupInfo] = useState(null);

  return (
     <>
      <div className="hidden">
        <Layers />
      </div>

      <ReactMapGL
          {...viewport}
          maxZoom={20}
          mapboxApiAccessToken="pk.eyJ1Ijoic2hhaHJ1a2gtMjAyMSIsImEiOiJja3hicDhxNDY0OTJzMnhsYWpsaGV5eGV4In0.CcDyQJPcjIPH4vMBKs6fPQ"
          mapStyle="mapbox://styles/shahrukh-2021/ckxeclk9ce9it14mjgugowsq7"
          onViewportChange={newViewport =>{
            setViewport({ ...newViewport });
          }}
          ref={mapRef}
        >
          {clusters.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const { cluster: isCluster, point_count: pointCount } = cluster.properties;

            if (isCluster) {
              return (
                <Marker key={cluster.id} latitude={latitude} longitude={longitude}>
                  <div
                    className="cluster-marker"
                    style={{
                      width: `${30 + (pointCount / points.length) * 20}px`,
                      height: `${30 + (pointCount / points.length) * 20}px`
                    }}
                    onClick={() => {
                      const expansionZoom = Math.min(
                        supercluster.getClusterExpansionZoom(cluster.id),
                        20
                      );
                      setViewport({
                        ...viewport,
                        latitude,
                        longitude,
                        zoom: expansionZoom,
                        transitionInterpolator: new FlyToInterpolator({ speed: 2 }),
                        transitionDuration: "auto"
                      });
                    }}
                  >
                    {pointCount}
                  </div>
                </Marker>
              );
            }

            return (
                <div>
                  <Marker
                    key={cluster.properties.id}
                    latitude={latitude}
                    longitude={longitude}
                  >
                    <button className="transformer-marker"
                      onClick={(e) => {
                        e.preventDefault();
                        setInfo(cluster);
                        setSidebar(true);
                      }}
                      // onMouseEnter={() => {
                      //   setPopupInfo(cluster);
                      //   setShowPopup(true);
                      // }}
                      // onMouseLeave={() => setShowPopup(false)}
                    >
                      {cluster.properties.type === "transformer" ? (
                        <img src="assets/images/transformer.png" alt="" />
                      ) : (
                        <img src="assets/images/meter.png" width="37" alt="" />
                      )}
                    </button>
                  </Marker>

                  {
                    // {showPopup && (
                    //   <Popup
                    //     longitude={longitude}
                    //     latitude={latitude}
                    //     anchor="bottom"
                    //     onClose={() => setShowPopup(false)}>
                    //     <div>
                    //       {popupInfo.properties.title}
                    //     </div>
                    //   </Popup>
                    // )}
                  }
                </div>
            );
          })}
        </ReactMapGL>

        {info ? (
          <Sidebar sidebar={sidebar} info={info} setInfo={setInfo} />
        ) : null}

        <div className="hidden">
          <Fab
          size="large"
          color="inherit"
          aria-label="List View"
          style={{
            background: "#ef5c29",
            position: "absolute",
            right: "1.5rem",
            bottom:" 1.5rem"
           }}
           onClick={() => {
             navigate('/transformers');
           }}
           >
            <MenuIcon style={{ fill: "#fff" }} />
          </Fab>
        </div>
    </>
  );
};

export default Map
