import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { collection, onSnapshot, query, limit, orderBy, where } from 'firebase/firestore';
import { db } from '../../firebase';

import { Chart as ChartJS, LineElement, Legend, Tooltip, PointElement, CategoryScale, LinearScale } from 'chart.js';
import { Line } from 'react-chartjs-2';

import CircularProgress from '@mui/material/CircularProgress';

ChartJS.register (
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Legend,
  Tooltip
)

function ReactivePowerChart({ id }) {
  const [dataA, setDataA] = useState([]);
  const [dataB, setDataB] = useState([]);
  const [dataC, setDataC] = useState([]);
  const [slot, setSlot] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const current_date_time =  moment().subtract(12, "hours").format("YYYY-MM-DD HH:mm:ss");

    const snapRef = collection(db, "data_logs/" + id + "/data");
    // const q = query(snapRef, orderBy("created_at", "asc"), where("created_at", ">=", current_date_time));
    const q = query(snapRef, orderBy("created_at", "asc"), limit(500));
    const unsubscribe = onSnapshot(q, (snapshot) => {
        const powerA = [];
        const powerB = [];
        const powerC = [];
        const powerSlot = [];
        snapshot.forEach((doc) => {
          powerA.push(doc.data().kvar1);
          powerB.push(doc.data().kvar2);
          powerC.push(doc.data().kvar3);
          powerSlot.push(moment(doc.data().created_at).format("HH:mm"));
        })
        setDataA(powerA);
        setDataB(powerB);
        setDataC(powerC);
        setSlot(powerSlot);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
    });

    return unsubscribe;

  }, [id]);

  const data = {
      labels: slot,
      datasets: [{
          label: 'Phase A',
          data: dataA,
          backgroundColor: [
            'rgba(229, 115, 115, 0.2)'
          ],
          borderColor: [
            'rgba(229, 115, 115, 1)'
          ],
          borderWidth: 2
      },{
          label: 'Phase B',
          data: dataB,
          backgroundColor: [
            'rgba(255, 241, 118, 0.2)'
          ],
          borderColor: [
            'rgba(255, 241, 118, 1)'
          ],
          borderWidth: 2
      },{
          label: 'Phase C',
          data: dataC,
          backgroundColor: [
            'rgba(121, 134, 203, 0.2)'
          ],
          borderColor: [
            'rgba(121, 134, 203, 1)'
          ],
          borderWidth: 2
      }]
    }

    const options = {
      maintainAspectRatio: false,
      // scales: {
      //     y: {
      //       beginAtZero: true
      //     }
      // },
      plugins: {
       legend: {
        display: true,
        position: 'top',
        title: {
          display: true,
          text: 'Reactive Power',
          font: {
            weight: 'bold',
            size: '14px'
          }
        }
       }
      }
    }

  return (
    <>
      {loading ? (
        <div className="chartSpinner">
          <CircularProgress />
        </div>
      ) : (
        <Line data={data} options={options} />
      )}
    </>
  );
};

export default ReactivePowerChart
