import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, query, limit, orderBy } from 'firebase/firestore';
import { db } from '../../firebase';
import { useParams } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import Nav from '../../layouts/Nav';
import Tabs from './Tabs';
import Breadcrumbs from '../../components/Breadcrumbs';


function TransformerView() {
  const { id } = useParams();
  const [transformer, setTransformer] = useState([]);
  // const [transformerInfo, setTransformerInfo] = useState();
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //
  //   const unsubscribe = onSnapshot(collection(db, 'crimes'), (snapshot) => {
  //     snapshot.forEach((doc) => {
  //       if (id === doc.id)
  //       setTransformerInfo(doc.data());
  //     });
  //   });
  //
  //   return unsubscribe;
  //
  // }, [id]);

  useEffect(() => {

    const snapRef = collection(db, "data_logs/" + id + "/data");
    const q = query(snapRef, orderBy("created_at", "desc"), limit(1));
    const unsubscribe = onSnapshot(q, (snapshot) => {
        const items = [];
        snapshot.forEach((doc) => {
            items.push(doc.data());
        })
        setTransformer(items[0]);
        setLoading(false);
    });

    return unsubscribe;

  }, [id]);

  return (
    <>
      <div className="hideBoxShadow">
        <Nav />
      </div>
      {loading ? (
        <>
          <div className="dataSpinner">
            <div className="spinner">
              <CircularProgress />
              <Typography variant="subtitle1" component="div" gutterBottom>
                Loading...
              </Typography>
            </div>
          </div>
        </>
      ) : (
        <div className="transformerView">
          <div style={{ margin: "48px 2px 0" }}>
            <Breadcrumbs />
          </div>
          <Tabs transformer={transformer} id={id} />
        </div>
      )}
    </>
  );
};

export default TransformerView;
