import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import CircularProgress from '@mui/material/CircularProgress';

function Alarms({ id }) {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [alarmsData, setAlarmsData] = useState();

  useEffect(() => {

    const unsubscribe = axios.get('https://amfk9yua14.execute-api.ap-south-1.amazonaws.com/prod/gettranscoalarmdata?meterId='+id+'&limit=10&offset=0').then((response) => {
        if (response.data.data1.length > 0) {
          setAlarmsData(response.data.data1);
          setLoading(false);
        } else {
          setMessage('No alarms found');
          setLoading(false);
        }
    });

    return unsubscribe;

  }, [id]);

  return (
    <>
      <div className="alarms">
        {loading ? (
          <div className="innerAlarms">
            <div className="tabDataSpinner">
              <CircularProgress />
            </div>
          </div>
        ) : (
          <>
            {!message ? (
              <>
                <List sx={{ p: '0' }}>
                  {alarmsData.map((item) => (
                    <ListItem key={item.id} alignItems="flex-start" className="alarm">
                      <div className={item.severity === "low" ? 'status minor' : 'status critical'}></div>
                      <ListItemAvatar>
                        <Avatar className="avatar" alt={item.severity} src="*" />
                      </ListItemAvatar>
                      <ListItemText
                        primary={item.severity === "low" ? 'Minor' : 'Critical'}
                        secondary={
                          <React.Fragment>
                          <Typography
                             sx={{ display: 'inline' }}
                             component="span"
                             variant="body2"
                             color="text.primary"
                           >
                             {item.reading}
                           </Typography>
                            {" — "} {item.description}
                          </React.Fragment>
                        }
                      />
                      <div edge="end" style={{ textAlign: 'right' }}>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                          className="date"
                        >
                         {moment(item.created_at).format('DD MMM, YYYY hh:mm A')}
                         </Typography>
                      </div>
                    </ListItem>
                  ))}
                </List>
              </>
            ) : (
              <div className="innerAlarms">
                <div className="noDataMessage">{message}</div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Alarms;
