import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, onSnapshot, query, limit, orderBy } from 'firebase/firestore';
import { db } from '../../../firebase';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';

import Alarms from '../../../components/Alarms';

function Sidebar({ sidebar, info, setInfo }) {
  const navigate = useNavigate();
  const [value, setValue] = useState('1');
  const [loading, setLoading] = useState(true);
  const [transformer, setTransformer] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRemove = () => {
    setInfo(null);
  };

  const transformerView = () => {
    navigate(`/transformers/${info.properties.id}`)
  }

  const meterView = () => {
    navigate(`/meters/${info.properties.id}`)
  }

  useEffect(() => {
    const snapRef = collection(db, "data_logs/" + info.properties.id + "/data");
    const q = query(snapRef, orderBy("created_at", "desc"), limit(1));
    const unsubscribe = onSnapshot(q, (snapshot) => {
        const items = [];
        snapshot.forEach((doc) => {
            items.push(doc.data());
        })
        setTransformer(items[0]);
        setLoading(false);
    });

    return unsubscribe;

  }, [info]);

  return (
    <>
      <div className={sidebar ? 'transformer-sidebar active' : 'transformer-sidebar'}>
        <AppBar color="inherit" position="static" className={info.properties.type === 'meter'? 'boxShadow': ''}>
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {info.properties.title}

              {info.properties.type === 'transformer' ? (
                <span className={info.properties.status === "active" ? 'success' : 'error'}>
                 {info.properties.status}
                </span>
              ) : (
                <span className={transformer.status === "active" ? 'success' : 'error'}>
                 {transformer.status}
                </span>
              )}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleRemove}
              color="inherit"
              style={{ marginRight: "-12px" }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        {info.properties.type === "transformer" ? (
          <>
            <div className="info-wrapper">
              <TabContext value={value}>
                <Box className="tabBox">
                  <TabList variant="fullWidth" onChange={handleChange} aria-label="lab API tabs example">
                    <Tab label="Reading" value="1" />
                    <Tab label="Info" value="2" />
                    <Tab label="Alarms" value="3" />
                  </TabList>
                </Box>

                <TabPanel className="tabPanel" value="1">
                  {loading ? (
                     <div className="readingSpinner">
                       <CircularProgress />
                     </div>
                  ) : (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                            Average Voltage
                          </Typography>
                          <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                            {transformer.voltageavg}
                          </Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                            Average Current
                          </Typography>
                          <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                            {transformer.currentavg}
                          </Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                            Oil Level
                          </Typography>
                          <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                            -
                          </Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                            Temperature
                          </Typography>
                          <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                            -
                          </Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                            Frequency
                          </Typography>
                          <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                            {transformer.frequency}
                          </Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                            Earthing
                          </Typography>
                          <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                            -
                          </Typography>
                        </Grid>
                      </Grid>

                      <Divider textAlign="center" style={{ margin: "24px 0", opacity: ".7"  }}>Voltage</Divider>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                            Phase A
                          </Typography>
                          <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                            {transformer.voltage1}
                          </Typography>
                        </Grid>

                        <Grid item xs={4}>
                          <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                            Phase B
                          </Typography>
                          <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                            {transformer.voltage2}
                          </Typography>
                        </Grid>

                        <Grid item xs={4}>
                          <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                            Phase C
                          </Typography>
                          <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                            {transformer.voltage3}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Divider textAlign="center" style={{ margin: "24px 0", opacity: ".7" }}>Current</Divider>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                            Phase A
                          </Typography>
                          <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                            {transformer.current1}
                          </Typography>
                        </Grid>

                        <Grid item xs={4}>
                          <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                            Phase B
                          </Typography>
                          <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                            {transformer.current2}
                          </Typography>
                        </Grid>

                        <Grid item xs={4}>
                          <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                            Phase C
                          </Typography>
                          <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                            {transformer.current3}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Divider textAlign="center" style={{ margin: "24px 0", opacity: ".7"  }}>Harmonics</Divider>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                            1st Level
                          </Typography>
                          <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                            -
                          </Typography>
                        </Grid>

                        <Grid item xs={4}>
                          <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                            2nd Level
                          </Typography>
                          <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                            -
                          </Typography>
                        </Grid>

                        <Grid item xs={4}>
                          <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                            3rd Level
                          </Typography>
                          <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                            -
                          </Typography>
                        </Grid>

                        <Grid item xs={4}>
                          <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                            4th Level
                          </Typography>
                          <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                            -
                          </Typography>
                        </Grid>

                        <Grid item xs={4}>
                          <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                            5th Level
                          </Typography>
                          <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                            -
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </TabPanel>

                <TabPanel className="tabPanel" value="2">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                        Address
                      </Typography>
                      <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                        {info.properties.address}
                      </Typography>
                    </Grid>

                  <Grid item xs={6}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                      Serial Number
                    </Typography>
                    <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                      {info.properties.srNo}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                      Model Number
                    </Typography>
                    <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                      {info.properties.modelNo}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                      KVA
                    </Typography>
                    <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                      {info.properties.kva}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                      Phase
                    </Typography>
                    <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                      {info.properties.phase}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                      Status
                    </Typography>
                    <Typography variant="body2" component="div" sx={{ flexGrow: 1, color: "#43a047", textTransform: "capitalize" }}>
                      <span className={info.properties.status === "active" ? 'text-success' : 'text-error'}>
                       {info.properties.status}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
                </TabPanel>

                <TabPanel className="tabPanel" value="3">
                   <Alarms id={info.properties.id} />
                </TabPanel>
              </TabContext>
            </div>

            <div style={{ textAlign: "center", padding: "0 24px 24px" }}>
              <Button className="btn-theme" variant="contained" size="large" endIcon={<ArrowRightAltIcon style={{ fill: "#fff" }} />} onClick={transformerView} style={{ width: "100%" }}>
                Vew More
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="meter-wrapper">
              {loading ? (
                 <div className="readingSpinner">
                   <CircularProgress />
                 </div>
              ) : (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                        Service Number
                      </Typography>
                      <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                        {info.properties.service_number}
                      </Typography>
                    </Grid>

                  <Grid item xs={6}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                      Consumption
                    </Typography>
                    <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                      {transformer.consumption}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                      Current
                    </Typography>
                    <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                      {transformer.current}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                      Voltage
                    </Typography>
                    <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                      {transformer.voltage}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                      Status
                    </Typography>
                    <Typography variant="body2" component="div" sx={{ flexGrow: 1, color: "#43a047", textTransform: "capitalize" }}>
                      <span className={transformer.status === "active" ? 'text-success' : 'text-error'}>
                       {transformer.status}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Divider textAlign="center" style={{ margin: "24px 0", opacity: ".7"  }}>Customer Details</Divider>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                      Name
                    </Typography>
                    <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                      {info.properties.customer_details.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                      Address
                    </Typography>
                    <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                      {info.properties.customer_details.address}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                      USCNO
                    </Typography>
                    <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                      {info.properties.customer_details.uscno}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                      AREA
                    </Typography>
                    <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                      {info.properties.customer_details.area}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                      CAT
                    </Typography>
                    <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                      {info.properties.customer_details.cat}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                      SC
                    </Typography>
                    <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                      {info.properties.customer_details.sc}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                      Phase
                    </Typography>
                    <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                      {info.properties.customer_details.phase}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                      LOAD
                    </Typography>
                    <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                      {info.properties.customer_details.load}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: ".9rem" }}>
                      MF
                    </Typography>
                    <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
                      {info.properties.customer_details.mf}
                    </Typography>
                  </Grid>
                </Grid>
                </>
              )}
            </div>
            <div style={{ textAlign: "center", padding: "0 24px 24px" }}>
              <Button className="btn-theme" variant="contained" size="large" endIcon={<ArrowRightAltIcon style={{ fill: "#fff" }} />} onClick={meterView} style={{ width: "100%" }}>
                Vew More
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Sidebar;
