import { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../firebase';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword ,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  confirmPasswordReset,
  signOut
} from 'firebase/auth';

const AuthContext = createContext({
  currentUser: null,
  register: () => Promise,
  login: () => Promise,
  signInWithGoogle: () => Promise,
  forgotPassword: () => Promise,
  resetPassword: () => Promise,
  logout: () => Promise,
})

export const useAuth = () => useContext(AuthContext)

export default function AuthContextProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setCurrentUser(user);
      window.localStorage.setItem("loginUser", JSON.stringify(user));
    })

    return () => {
      unsubscribe()
    }
  }, [])

  function register(email, password) {
    return createUserWithEmailAndPassword(auth, email, password)
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  function signInWithGoogle() {
    const provide = new GoogleAuthProvider()
    return signInWithPopup(auth, provide)
  }

  function forgotPassword(email) {
    return sendPasswordResetEmail(auth, email, {
      url: 'https://transco.globalwomanonline.com/login',
    })
  }

  function resetPassword(oobCode, newPassword) {
    return confirmPasswordReset(auth, oobCode, newPassword)
  }

  function logout() {
    window.localStorage.removeItem("loginUser");
    return signOut(auth)
  }

  const value = {
    currentUser,
    register,
    login,
    signInWithGoogle,
    forgotPassword,
    resetPassword,
    logout
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
