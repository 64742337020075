import React, { useState } from 'react';
import LayersIcon from '@mui/icons-material/Layers';
import Button from '@mui/material/Button';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';

import { LayersMenu } from '../../../data/layers-menu';

function Layers() {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
     <Button onClick={showSidebar} className="layer-toggle" variant="contained" startIcon={<LayersIcon />}>
        LAYERS
     </Button>

      <div className={sidebar ? 'layer-sidebar active' : 'layer-sidebar'}>
        <AppBar color="inherit" position="static" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}>
          <Toolbar variant="dense">
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Layers
            </Typography>
            <IconButton
              aria-label="close"
              onClick={showSidebar}
              color="inherit"
              style={{ marginRight: "-12px" }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <div style={{ height: "calc(100% - 48px)", paddingTop: "10px", overflow:"auto" }}>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', padding: "0" }}>
            {LayersMenu.map((item, index) => {
              return (
                <ListItemButton key={index} className={item.cName}>
                  <ListItemAvatar>
                    <Avatar>
                      <ImageIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={item.title} secondary={item.subTitle} />
                </ListItemButton>
              )
            })}
          </List>
        </div>
      </div>
    </>
  );
};

export default Layers;
