import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import NotFound from './pages/NotFound';

import Home from './pages/Home';
import Transformers from './pages/Transformers';
import TransformerView from './pages/TransformerView';
import MeterView from './pages/MeterView';

function AppRoutes() {
  return (
    <>
      <Routes>
         <Route path='/login' element={<Login/>} />
         <Route path='/signup' element={<SignUp/>} />
         <Route path='/forgot-password' element={<ForgotPassword/>} />
         <Route path='/reset-password' element={<ResetPassword/>} />

         {
           // Private Route
         }
         <Route element={<PrivateRoute/>}>
            <Route exact path='/' element={<Home/>} />
         </Route>
         <Route element={<PrivateRoute/>}>
            <Route path='/transformers' element={<Transformers/>} />
         </Route>
         <Route element={<PrivateRoute/>}>
            <Route path='/transformers/:id' element={<TransformerView/>} />
         </Route>
         <Route element={<PrivateRoute/>}>
            <Route path='/meters/:id' element={<MeterView/>} />
         </Route>

         {
           // 404 Page
         }
         <Route path='*' element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
