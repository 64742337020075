import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { collection, onSnapshot, query, orderBy, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { useParams } from 'react-router-dom';

import { Chart as ChartJS, BarElement, Legend, CategoryScale, LinearScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import CircularProgress from '@mui/material/CircularProgress';

ChartJS.register (
  BarElement,
  CategoryScale,
  LinearScale,
  Legend
)

function MeterConsumptionChart() {
  const { id } = useParams();
  const [consumptionData, setConsumptionData] = useState([]);
  const [slot, setSlot] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

      const current_date_time =  moment().format("YYYY-MM-DD HH:mm:ss");

      const snapRef = collection(db, "data_logs/" + id + "/data");
      // const q = query(snapRef, orderBy("created_at", "asc"), where("created_at", ">=", current_date_time));
      const q = query(snapRef, orderBy("created_at", "asc"));
      const unsubscribe = onSnapshot(q, (snapshot) => {
      const items = [];
      const consumptionSlot = [];
      snapshot.forEach((doc) => {
        items.push(doc.data().consumption);
        consumptionSlot.push(moment(doc.data().created_at).format("HH:mm"));
      })
      setConsumptionData(items);
      setSlot(consumptionSlot);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
  });

  return unsubscribe;

}, [id]);

  const data = {
      labels: slot,
      datasets: [{
          label: 'Consumption',
          data: consumptionData,
          backgroundColor: [
            'rgba(121, 134, 203, 0.2)'
          ],
          borderColor: [
            'rgba(121, 134, 203, 1)'
          ],
          borderWidth: 2
      }]
    }

    const options = {
      maintainAspectRatio: false,
      // scales: {
      //     y: {
      //       beginAtZero: true
      //     }
      // },
      plugins: {
       legend: {
        display: false,
        position: 'top',
        title: {
          display: false,
          text: 'Consumption',
          font: {
            weight: 'bold',
            size: '14px'
          }
        }
       }
      }
    }

  return (
    <>
      {loading ? (
        <div className="chartSpinner">
          <CircularProgress />
        </div>
      ) : (
        <Bar data={data} options={options} />
      )}
    </>
  );
};

export default MeterConsumptionChart
