import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import Dashboard from '../Dashboard';
import HistoricalData from '../HistoricalData';
import Alarms from '../../../components/Alarms';

function Tabs({ transformer, id }) {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
     <TabContext value={value}>
       <Box className="tabBox">
         <TabList onChange={handleChange} aria-label="lab API tabs example">
           <Tab label="Dashboard" value="1" />
           <Tab label="Historical Data" value="2" />
           <Tab label="Alarms" value="3" />
         </TabList>
       </Box>
       <TabPanel className="tabPanel" value="1">
         <Dashboard transformer={transformer} id={id} />
       </TabPanel>
       <TabPanel className="tabPanel" value="2" style={{ marginBottom: "0", paddingBottom: "0" }}>
         <HistoricalData id={id} />
       </TabPanel>
       <TabPanel className="tabPanel" value="3">
         <Alarms id={id} />
       </TabPanel>
     </TabContext>
    </>
  );
};

export default Tabs;
